var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import styled from 'styled-components';
import { Text } from 'evolux-ui-v2';
import ReactMarkdown from 'react-markdown';
import './markdownStyles.css'; // Styled components does not work well with ReactMarkdown
import { useI18n } from '../../../commons/useI18n';
import { parseMessage } from './utils';
import { MESSAGE_SIZE_TO_COLLAPSE as MAX_CHARS, COLLAPSED_MESSAGE } from '../../../../config';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
var ReadMore = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 14px;\n  color: var(--link);\n  cursor: pointer;\n"], ["\n  font-size: 14px;\n  color: var(--link);\n  cursor: pointer;\n"])));
function TextContent(_a) {
    var message = _a.message;
    var i18n = useI18n();
    var _b = __read(React.useState(!COLLAPSED_MESSAGE), 2), isExpanded = _b[0], setIsExpanded = _b[1];
    return (React.createElement(Wrapper, null,
        React.createElement(ReactMarkdown, { className: "markdown-styles", linkTarget: "_blank", components: {
                h1: function (_a) {
                    var node = _a.node, props = __rest(_a, ["node"]);
                    return React.createElement(Text, __assign({}, props, { size: "lg", type: "title" }));
                },
                h2: function (_a) {
                    var node = _a.node, props = __rest(_a, ["node"]);
                    return React.createElement(Text, __assign({}, props, { size: "md", type: "title" }));
                },
                h3: function (_a) {
                    var node = _a.node, props = __rest(_a, ["node"]);
                    return React.createElement(Text, __assign({}, props, { size: "sm", type: "title" }));
                },
                h4: function (_a) {
                    var node = _a.node, props = __rest(_a, ["node"]);
                    return (React.createElement(Text, __assign({}, props, { size: "sm", type: "title", as: "h4" })));
                },
                h5: function (_a) {
                    var node = _a.node, props = __rest(_a, ["node"]);
                    return (React.createElement(Text, __assign({}, props, { size: "sm", type: "title", as: "h5" })));
                },
                h6: function (_a) {
                    var node = _a.node, props = __rest(_a, ["node"]);
                    return (React.createElement(Text, __assign({}, props, { size: "sm", type: "title", as: "h6" })));
                },
                p: function (_a) {
                    var node = _a.node, props = __rest(_a, ["node"]);
                    return React.createElement(Text, __assign({}, props, { as: "p" }));
                },
                em: function (_a) {
                    var node = _a.node, props = __rest(_a, ["node"]);
                    return React.createElement(Text, __assign({}, props, { italic: true }));
                },
                b: function (_a) {
                    var node = _a.node, props = __rest(_a, ["node"]);
                    return React.createElement(Text, __assign({}, props, { bold: true }));
                },
                strong: function (_a) {
                    var node = _a.node, props = __rest(_a, ["node"]);
                    return React.createElement(Text, __assign({}, props, { bold: true }));
                },
                small: function (_a) {
                    var node = _a.node, props = __rest(_a, ["node"]);
                    return React.createElement(Text, __assign({}, props, { size: "sm" }));
                },
                li: function (_a) {
                    var node = _a.node, props = __rest(_a, ["node"]);
                    return React.createElement(Text, __assign({}, props, { as: "li" }));
                },
                a: function (_a) {
                    var node = _a.node, props = __rest(_a, ["node"]);
                    return React.createElement(Text, __assign({}, props, { type: "link" }));
                },
            } }, (isExpanded)
            ? parseMessage(message.message)
            : parseMessage("".concat(message.message.slice(0, MAX_CHARS)).concat(message.message.length > MAX_CHARS ? '...' : ''))),
        (message.message.length >= MAX_CHARS && !isExpanded && true) && (React.createElement(ReadMore, { role: "button", tabIndex: 0, onClick: function () { return setIsExpanded(true); } }, i18n._('Read more')))));
}
export default TextContent;
var templateObject_1, templateObject_2;
